const localeDefault = {
  "486_4486": "Comming Soon",
  "29_2795": "Policies",
  "486_4369": "Service is required",
  "461_3518": "Cancel",
  "78_7491": "idm_organization_path_detail",
  "71_6692": "1",
  "415_6710": "Create External Permission",
  "466_4102": "The Identity Provider has been successfully activated.",
  "415_6850": "Back",
  "461_3536": "Warning",
  "411_6305": "Policy",
  "65_5672": "Management approval child path change status",
  "178_9660": "Name",
  "135_8565": "Enter Resources",
  "417_10124": "Update Organization",
  "460_3269": "Request approval successful!",
  "466_4107": "Notification",
  "462_3585": "The External Permission has been successfully updated.",
  "415_7524": "Deactive",
  "411_6175": "Internal Permission",
  "177_9397": "KDDI",
  "466_3896": "Notification",
  "66_6091": "Action",
  "417_8685": "Enter Role",
  "417_8389": "13",
  "405_4961": "Phone",
  "471_4312": "Notification",
  "32_4859": "Logo",
  "91_8390": "Confirm Password",
  "90_7895": "Duong A",
  "66_6449": "External Group Permission",
  "66_6101": "External Permission",
  "417_8388": "Active User",
  "32_4822": "",
  "487_4904": "29-01-2024 15:52",
  "417_9594": "profile",
  "33_5472": "Number of times OTP is allowed to be sent per day",
  "411_6582": "Update",
  "32_4725": "Logo",
  "90_8150": "Import",
  "400_2160": "23",
  "487_4929": "My Notification",
  "417_8795": "Van",
  "417_9567": "Client Secret",
  "460_3158": "Please enter an integer value",
  "78_7274": "api-control-plane",
  "91_8408": "Address 2",
  "415_6718": "External Permission",
  "255_2001": "Country",
  "417_10047": "Save",
  "416_7747": "Select",
  "461_3555": "The Role has been successfully activated.",
  "461_3446": "Notification",
  "417_8393": "13",
  "66_6433": "Method",
  "78_7496": "GET",
  "417_9601": "image 4",
  "417_8654": "/",
  "I415_7651;645_18156": "Are you sure you want to delete Administrator?",
  "66_6466": "External Group Permission",
  "417_10043": "Phone",
  "411_5545": "/",
  "487_4951": "Search by object",
  "417_9661": "Identity Provider",
  "66_5803": "Select",
  "417_8011": "Matsubara",
  "416_7904": "Zip/Postal Code",
  "65_5693": "Management idm organization child path update",
  "415_7129": "Description",
  "400_2148": "Active",
  "515_10450": "Your password is not right policies.",
  "405_5045": "Preview",
  "405_5025": "+84",
  "416_7852": "Password",
  "90_7865": "Lê B",
  "33_5355": "Check allowed characters (including |a-zA-z0-9| and allowed special symbol).",
  "78_7498": "966",
  "400_3814": "Administrator Role",
  "416_7705": "Phone",
  "90_7957": "Create",
  "90_7933": "16",
  "33_5239": "Require numbers.",
  "487_4998": "Subject",
  "178_9648": "10",
  "405_5040": "Organization",
  "400_2251": "Create",
  "417_8664": "First Name",
  "415_7286": "Status",
  "460_3189": "The OTP Management has been successfully updated.",
  "417_8674": "Enter Middle Name!",
  "78_7304": "1107",
  "416_7706": "Password",
  "78_7244": "ID",
  "178_9523": "Name",
  "177_9160": "Callback URL",
  "461_3533": "Are your sure to delete Policy?",
  "411_6272": "IDM",
  "280_1845": "1",
  "30_3885": "Policies",
  "487_4902": "Get IDM",
  "29_2939": "Description",
  "411_5896": "Preview - Resource",
  "32_4719": "",
  "410_5525": "Administrator Role",
  "177_9313": "Enter Platform",
  "415_7278": "Action",
  "32_4541": "om.japan@gmail.com",
  "410_5318": "User Role Name",
  "66_6327": "",
  "415_7330": "Tran A",
  "417_9574": "CID_1234567890",
  "415_7396": "13",
  "66_5795": "Save",
  "474_4355": "Warning",
  "411_6089": "Management approval child path change status",
  "461_3530": "Cancel",
  "32_4685": "Representative",
  "466_4020": "Notification",
  "415_7045": "Create External Group Permission",
  "405_5042": "Preview Organization",
  "32_4804": "Phone",
  "33_4934": "Access Limit",
  "487_5009": "Detail Notification",
  "32_4570": "Netko Company",
  "416_7729": "City",
  "177_9425": "Icon",
  "177_9287": "Platform",
  "I466_4003;645_18155": "Warning",
  "33_5360": "7",
  "136_8704": "Enter Method",
  "417_8727": "Netko Solution",
  "136_8678": "Delete",
  "466_3978": "Delete",
  "405_5017": "tax 111",
  "385_3854": "Edit",
  "400_2712": "Organization",
  "400_2145": "47",
  "487_5008": "/",
  "417_10040": "Representative",
  "79_7733": "/api/v1/internal/approvals/{id}/change_approval_status12/",
  "515_10440": "Security",
  "487_5003": "Thurday, 28-02-2024 12:14",
  "I466_4109;645_18155": "Warning",
  "417_8885": "User",
  "177_9307": "Upload",
  "400_2241": "User Roles",
  "417_9565": "Auth Provider",
  "I415_6987;645_18160;30_31931": "Confirm",
  "177_9389": "https://idm-portal-dev.newlands.organiclink.cloud/identity_provider/create",
  "91_8453": "+89",
  "416_7704": "Organization",
  "460_3276": "You need to select records which you want action.",
  "417_9580": "KDDI",
  "417_8397": "13",
  "417_10101": "M Smith",
  "I417_8910;645_18159;30_32137": "Cancel",
  "415_7400": "8",
  "66_6306": "Resource",
  "33_5500": "Key Token",
  "417_8655": "Create User",
  "178_9823": "lorem Ipsum",
  "411_6171": "Internal Permission",
  "460_3172": "Please enter an integer value",
  "I417_8910;645_18156": "Are you sure you want to delete Identity Provider?",
  "91_8431": "Roles",
  "466_4027": "The User has been successfully activated.",
  "461_3427": "Request approval successful!",
  "32_4845": "M Smith",
  "466_3977": "Deactive",
  "I423_2292;623_21687": "An error has occurred, please try again or contact the administrator.",
  "385_3618": "Management approval child path view",
  "417_9766": "10",
  "400_2143": "Administrator",
  "411_6580": "Back",
  "410_5497": "Permission Type",
  "66_6474": "/resource/edit",
  "29_2935": "Policy Name",
  "29_3037": "Resources",
  "415_7603": "Delete",
  "I513_9956;154_61665": "New password",
  "91_8406": "State/Provine",
  "415_7133": "External Group Permission 1",
  "486_4364": "Method",
  "385_2211": "Applications",
  "28_2440": "Resources",
  "415_7404": "10",
  "460_3157": "Please input Minimum Password Length!",
  "66_6472": "permission.external",
  "385_3858": "Delete",
  "410_5496": "Policies",
  "136_8614": "Enter Organization!",
  "417_8191": "Preview",
  "176_8820": "ID",
  "460_3250": "Cancel",
  "66_6443": "Resource",
  "90_7889": "Le B",
  "I466_3929;645_18159;30_32137": "Cancel",
  "415_7567": "2",
  "466_3927": "Notification",
  "405_5057": "Organization",
  "33_5484": "Update",
  "I466_3945;645_18159;30_32137": "Cancel",
  "32_4586": "Organization Type",
  "415_7360": "tan.le+3@netko-solution.com",
  "415_7306": "test92@gmail.com",
  "486_4375": "Select",
  "400_2119": "Organic Mobility Passenger",
  "415_7348": "test12@gmail.com",
  "32_4693": "Enter The Email",
  "78_7480": "api-idm",
  "91_8439": "Address 2",
  "466_3991": "History",
  "177_9301": "Select",
  "461_3407": "Notification",
  "466_4146": "The Application has been successfully updated.",
  "417_9687": "-",
  "65_5660": "835",
  "65_5636": "837",
  "178_9827": "Status",
  "467_4260": "Phone is invalid!",
  "415_6737": "permission.external",
  "136_8702": "Enter Organization",
  "91_8457": "Netko Solution",
  "66_5791": "Service",
  "513_9941": "Change Password",
  "466_3921": "Notification",
  "32_4716": "Country",
  "486_4373": "Attach External Permission",
  "487_4903": "test idm . From service api-idm raise error 200",
  "411_5861": "/",
  "78_7512": "962",
  "461_3542": "Notification",
  "79_7735": "root_approval.approval_path_change_status",
  "411_6102": "Action",
  "65_5590": "Action",
  "411_6260": "Policy Name",
  "417_8679": "Enter Last Name!",
  "32_4800": "Organization Code",
  "30_3100": "Resources",
  "91_8162": "Active",
  "I466_4002;645_18155": "Warning",
  "415_7118": "External Group Permission",
  "385_2172": "TOP",
  "402_2375": "Deactive",
  "177_9139": "14:01 2024-02-01",
  "461_3439": "Notification",
  "411_5544": "Resource",
  "78_7540": "root_idm_organization.idm_organization_path_update",
  "402_2391": "History",
  "462_3591": "Notification",
  "400_3296": "/",
  "66_6446": "Path",
  "415_7130": "Organization",
  "417_9563": "Client ID",
  "461_3521": "Are your sure to deactive Organization?",
  "411_5869": "Resource",
  "32_4682": "Tax-Code",
  "78_7470": "1104",
  "66_5971": "Action",
  "178_9826": "fgcvbfgjhghjghjgjghjdfwersdf",
  "385_2861": "Bien Hoang update Translation Task from TODO to DONE\n10:23 16/11/2023",
  "177_9137": "KDDI",
  "400_2225": "Deactive",
  "415_7037": "Delete",
  "405_5019": "M Smith",
  "178_9689": "10",
  "280_1843": "Enforce Password History",
  "486_4363": "Service",
  "79_7727": "Resource",
  "385_2218": "Internal Permission",
  "79_7731": "Save",
  "385_3620": "Management approval child path create",
  "405_5050": "Upload",
  "91_8176": "Active",
  "417_9592": "Dang Nhap",
  "79_7563": "Path",
  "417_8955": "Delete",
  "400_2177": "User",
  "467_4298": "Approval rejected successfully",
  "33_5502": "eyJhbGciOiJIUzl1NilslnR5cCl6lkpXVCJ9",
  "91_8451": "123663",
  "28_2454": "Applications",
  "217_1682": "You need to select records which you want action.",
  "66_6328": "netko-company-service-1",
  "400_2191": "Organic Mobility External Driver",
  "411_6080": "Update",
  "417_8653": "User",
  "177_9507": "image 4",
  "486_4485": "Vehicle List",
  "177_9380": "Auth Provider",
  "177_9311": "Enter Name",
  "417_8789": "First Name",
  "410_5417": "User Role",
  "415_7311": "Active",
  "66_5956": "Permission",
  "I423_2957;611_93387": "The Organization has been successfully updated.",
  "486_4361": "Permission",
  "177_9186": "Client Secret",
  "385_2199": "Administrator",
  "78_7280": "1106",
  "410_5536": "Enter Organization",
  "487_5002": "test idm . From service api-idm raise error 200",
  "457_3137": "Add IP Addresses",
  "411_6078": "Internal Permission",
  "417_9768": "InActive",
  "417_10085": "Phoenix",
  "461_3512": "Warning",
  "417_10127": "Update",
  "177_9387": "Save",
  "411_6404": "rbac_permission_path_selected",
  "91_8461": "Country",
  "411_6087": "Back",
  "66_6123": "2",
  "385_3613": "969",
  "466_3976": "Active",
  "385_3603": "973",
  "I466_4109;645_18156": "Are you sure you want to active Identity Provider?",
  "33_5245": "Require alphabetic letter.",
  "415_6709": "/",
  "466_3909": "The Administrator has been successfully created.",
  "71_6668": "External Group Permission 1",
  "400_2201": "User",
  "405_4983": "IDM Quadruple",
  "415_6759": "Preview External Permission",
  "66_6484": "GET",
  "32_4476": "036985214",
  "411_6593": "Org test 229",
  "66_6468": "Save",
  "415_6749": "GET",
  "417_8704": "Email",
  "405_5001": "Phoenix",
  "32_4812": "freune@gmail.com",
  "405_5009": "Country",
  "411_6265": "Description",
  "255_2008": "Update",
  "177_9296": "Name",
  "466_4083": "Notification",
  "178_9841": "App Icon",
  "416_7902": "State/Province",
  "411_6426": "Preview",
  "255_1969": "Nick name",
  "416_7826": "Create Administrator",
  "400_2153": "Active",
  "32_4803": "Organization Type",
  "417_8797": "Last Name",
  "415_7416": "Search",
  "400_3313": "/",
  "417_8396": "Locked User",
  "90_7935": "13",
  "33_5121": "To",
  "65_5703": "976",
  "177_9164": "URL",
  "487_4944": "99+",
  "415_7372": "Active",
  "417_9558": "Provider",
  "66_6052": "Can change status action approval",
  "461_3543": "The Role has been successfully updated.",
  "66_6151": "External Permission 01",
  "176_8852": "Action",
  "400_3305": "Admin Password Policy",
  "487_5005": "Back",
  "I415_6665;645_18160;30_31931": "Confirm",
  "400_3336": "OTP Management",
  "177_9401": "Web",
  "405_5054": "*****",
  "486_4359": "Permission",
  "178_9540": "Active",
  "28_2462": "145",
  "466_4032": "Notification",
  "33_5197": "Minimum Password Length (Characters)",
  "461_3524": "Warning",
  "30_3099": "Group Permission",
  "66_5797": "Internal Permission",
  "474_4351": "Confirm",
  "417_8027": "State/Province",
  "178_9849": "image 4",
  "33_5362": "20",
  "30_3887": "Select",
  "402_2396": "Edit",
  "255_1962": "Phone",
  "178_9525": "Status",
  "I411_6023;645_18156": "Are you sure you want to delete Internal Permission?",
  "400_1947": "Create Policy",
  "78_7541": "root_idm_organization.idm_organization_path_request_approval",
  "467_4268": "Your Resource should not be empty.",
  "417_9942": "Male",
  "415_7309": "Active",
  "66_6476": "Netko Company",
  "66_6321": "permission.external",
  "417_10042": "Organization Type",
  "415_6729": "Path",
  "400_2205": "Organic Mobility Driver",
  "I466_4110;645_18160;30_31931": "Confirm",
  "400_3221": "of",
  "514_10281": "/",
  "65_5594": "Name",
  "I415_6665;645_18155": "Warning",
  "33_5230": "20",
  "467_4290": "Sent email successful!",
  "400_3332": "OTP Management",
  "411_6591": "Org test 22 Passenger",
  "417_10039": "Organization Code",
  "30_3108": "IDM",
  "466_4095": "Notification",
  "405_4963": "Confirm Root Password",
  "417_8194": "Back",
  "415_6730": "Organization",
  "400_2014": "Policy",
  "411_6096": "Can change status action approval",
  "66_6324": "GET",
  "514_10285": "Personal Data Usage",
  "30_3097": "Policy Name",
  "32_4847": "Service Provider 2B",
  "415_7696": "Deactive",
  "I466_4002;645_18159;30_32137": "Cancel",
  "I466_4109;645_18160;30_31931": "Confirm",
  "415_6741": "Netko Company",
  "91_8205": "Active",
  "415_7563": "KDDI",
  "411_6589": "Attach External Permission",
  "416_7912": "Om Admin",
  "417_10162": "Service Provider 2C",
  "79_7714": "Select",
  "32_4527": "Root Account",
  "466_3902": "Notification",
  "405_5007": "85015",
  "460_3155": "Notification",
  "415_7352": "test31@gmail.com",
  "416_7828": "Administrator",
  "30_4387": "Organic Mobility",
  "405_4985": "freune@gmail.com",
  "91_8200": "Bùi Phương Thảo",
  "400_3811": "/",
  "400_2133": "Organic Mobility Owner",
  "90_7893": "Nguyen B",
  "515_10436": "Security",
  "460_3170": "Minimum password length can not bigger than Maximum password length.",
  "416_7933": "Update",
  "66_6465": "Method",
  "410_5323": "User Role",
  "411_6266": "Has parent Policy",
  "66_6329": "",
  "402_2400": "Delete",
  "514_10307": "Service",
  "78_7466": "",
  "79_7570": "/api/v1/user",
  "91_8385": "Password",
  "400_2245": "Search",
  "66_6095": "Name",
  "417_8687": "First Name",
  "417_8192": "-",
  "177_9314": "Enter Client ID",
  "487_4945": "Setting",
  "136_8615": "Enter Tax-Code!",
  "514_10282": "Personal Data Usage",
  "460_3256": "Warning",
  "I513_9981;154_61666;75_53865;497_42641;30_32137": "Deactivate account",
  "415_7394": "16",
  "415_7425": "13",
  "385_3839": "Delete",
  "415_6757": "External Permission",
  "417_9003": "Identity",
  "487_4907": "Approve",
  "460_3168": "Please input Minimum Password Length!",
  "416_7910": "admin.1@organiclink.cloud",
  "385_3564": "970",
  "385_2226": "External Group Permission",
  "I385_2857;30_31991": "Approve",
  "415_6758": "/",
  "I385_2826;405_11590;405_13233;144_59614": "99+",
  "417_8662": "First Name",
  "400_3292": "Access",
  "79_7719": "root.path.view",
  "29_3039": "Select",
  "461_3440": "The Organization has been successfully deleted.",
  "90_7945": "11",
  "73_7153": "Netko Company",
  "280_1842": "1",
  "402_2376": "Reset Password",
  "417_8392": "Pending User",
  "78_7505": "idm_organization_path_update",
  "466_3890": "Notification",
  "176_8880": "Dang Nhap",
  "417_10097": "Aaron",
  "417_9004": "Create",
  "400_2126": "Organic Mobility Primary Driver",
  "400_2565": "Admin Portal",
  "681_2527": "Admin Portal",
  "683_2531": "Export Management",
  "681_2529": "Client Portal",
  "90_7941": "9",
  "32_4577": "image 2",
  "415_6708": "External Permission",
  "461_3447": "The Organization has been successfully active.",
  "461_3560": "Notification",
  "136_8617": "Enter First Name!",
  "405_4959": "Tax-Code",
  "416_7932": "Administrator",
  "487_4874": "General",
  "461_3487": "Reset",
  "515_10427": "Require lowercase letter",
  "385_3550": "Description",
  "32_4841": "Aaron",
  "461_3355": "Notification",
  "474_4349": "Cancel",
  "177_9159": "Client Secret",
  "417_9827": "View",
  "415_7402": "9",
  "411_6294": "Management rbac permission child path selected",
  "415_6728": "Resource",
  "400_2109": "Action",
  "415_7338": "Nguyen B",
  "I456_3100;623_21593": "Your IP address FROM needs to be smaller than IP address TO.",
  "411_6392": "Policies",
  "137_8808": "Enter Confirm Password!",
  "137_8809": "Enter Organization",
  "91_8404": "Address 1",
  "461_3506": "Cancel",
  "415_6732": "Method",
  "461_3408": "The Organization has been successfully created.",
  "411_6270": "Management rbac permission child path selected",
  "32_4830": "Phoenix",
  "415_7609": "History",
  "78_7255": "Action",
  "73_7144": "Organization",
  "461_3389": "Notification",
  "417_10045": "Confirm Root Password",
  "135_8580": "Enter Permission Type",
  "32_4704": "Address",
  "417_10083": "Phoenix",
  "91_8168": "Active",
  "91_8170": "Active",
  "416_7872": "First Name",
  "I513_9970;30_32066": "Change password",
  "415_7437": "13",
  "411_6263": "Group Permission",
  "467_4287": "You have successfully approved the request.",
  "416_7698": "First Name",
  "I385_2823;636_27910": "ABC Corporation",
  "411_6497": "Administrator Role",
  "400_2170": "User",
  "32_4575": "image 1",
  "487_4876": "99+",
  "417_9551": "Provider",
  "400_3288": "/",
  "487_4995": "Detail Notification",
  "417_8025": "City",
  "400_3331": "/",
  "400_3212": "page",
  "415_7520": "Deactive",
  "30_4311": "Organization Code",
  "515_10428": "Require uppercase letter",
  "461_3374": "POST",
  "66_5881": "Action",
  "91_8412": "City",
  "I411_5693;645_18155": "Warning",
  "I385_2823;643_12017": "Activity Logs",
  "417_9764": "android",
  "177_9264": "Enter Auth Provider",
  "461_3349": "External Permission is required",
  "78_7259": "Name",
  "405_4954": "Organization",
  "385_3582": "967",
  "65_5658": "Management idm organization child path selected",
  "30_3883": "External Permissions",
  "416_7920": "+84",
  "400_2194": "User",
  "176_8968": "2",
  "176_8856": "Logo",
  "466_4090": "The Identity Provider has been successfully created.",
  "385_2181": "Organization",
  "417_8707": "Roles",
  "416_7929": "/",
  "417_9909": "Last Name",
  "66_6344": "Select",
  "417_8719": "Test92@gmail.com",
  "91_8445": "Zip/Postal Code",
  "33_5116": "Check here if you want to restrict access by IP address.",
  "28_2447": "Policies",
  "417_9689": "Back",
  "411_6418": "External Permissions",
  "405_5015": "Aaron",
  "417_10044": "Root Password",
  "417_8023": "Address 2",
  "467_4295": "Changed Successful!",
  "184_24818": "Application",
  "91_8399": "Select",
  "415_7428": "Active User",
  "460_3190": "Please input number of OTPs!",
  "460_3188": "Notification",
  "29_2813": "Search",
  "178_9662": "App ID",
  "385_2175": "Permission",
  "416_7900": "City",
  "33_5341": "Require alphabetic letter.",
  "178_9832": "Platform",
  "487_4977": "Silent",
  "78_7535": "root_approval.approval_path_detail",
  "467_4294": "Import successful!",
  "417_10091": "Country",
  "33_5482": "Back",
  "66_6342": "External Permission",
  "461_3497": "Are your sure to delete Organization?",
  "460_3252": "Confirm",
  "487_5000": "GET IDM",
  "466_4000": "Delete",
  "467_4291": "Deleted successful!",
  "I411_6023;645_18155": "Warning",
  "255_1993": "Address 2",
  "66_6097": "Service Code",
  "90_7891": "Pham A",
  "417_8007": "Phone",
  "400_2582": "Develop Console",
  "416_7867": "Role",
  "486_4378": "Enter External Permission!",
  "66_6325": "PUT",
  "416_7824": "Administrator",
  "467_4289": "Reset password successful. Please login to go to the Home Page!",
  "400_3211": "10  /",
  "415_7241": "Update",
  "416_7831": "Create",
  "417_8878": "Update User",
  "415_7546": "Deactive",
  "515_10432": "Check allowed characters (including |a-zA-z0-9| and allowed special symbol)",
  "460_3191": "Please input Key ID!",
  "417_10069": "2915 N 21st Ave",
  "417_8675": "Enter Last Name!",
  "385_3592": "Management rbac permission child path selected",
  "78_7306": "1105",
  "176_8990": "Identity Provider",
  "417_10161": "Service Provider 2B",
  "90_7955": "Search",
  "410_5325": "Select",
  "66_6323": "HR Permission",
  "417_8721": "123663",
  "I513_9956;154_61666;75_53865;250_37564": "Enter new password",
  "400_2115": "User Type",
  "417_8709": "Address 1",
  "32_4930": "imd-quadruple",
  "33_5133": "Update",
  "136_8679": "Enter Internal Permission Name!",
  "I466_3945;645_18156": "Are you sure you want to deactive Administrator?",
  "78_7526": "969",
  "415_7538": "Deactive",
  "461_3368": "The Resource has been successfully created.",
  "487_4978": "Turn on if you would like to receive notifications without popups and sound.",
  "461_3488": "Reset Password",
  "417_8672": "Last Name",
  "91_8433": "Save",
  "515_10451": "Confirm Password needs to match with Password",
  "417_10036": "Organization",
  "411_6074": "Internal Permission",
  "461_3396": "Request approval successful!",
  "29_2947": "Select",
  "66_6175": "1",
  "32_4714": "State/Prefecture",
  "417_8385": "13",
  "28_449": "7",
  "415_7358": "test11@gmail.com",
  "32_4814": "2915 N 21st Ave",
  "32_4797": "Organization",
  "177_9261": "Enter Config URL!",
  "466_4015": "The User has been successfully created.",
  "136_8706": "Enter Path",
  "487_4962": "Tasks",
  "177_9304": "Icon",
  "30_4390": "organic-mobility",
  "410_5523": "Update Administrator Role",
  "33_5233": "@#$%^&",
  "177_9289": "Select",
  "177_9393": "0",
  "411_6424": "-",
  "411_6604": "Back",
  "515_10424": "Task",
  "73_7142": "External Group Permission Name",
  "411_6414": "Internal Permissions",
  "I474_4328;623_21593": "Users need to change their password when they first sign in.",
  "417_9881": "Application",
  "411_5873": "Back",
  "28_2441": "145",
  "411_5891": "Preview Resource",
  "423_1918": "Notification",
  "30_4273": "Status",
  "461_3509": "Are your sure to active Organization?",
  "411_6385": "Update",
  "460_3166": "Please input Allow Special Symbol!",
  "514_10301": "Created At",
  "78_7508": "api-idm",
  "65_5723": "Internal Permission",
  "400_3312": "Update Organization",
  "32_4505": "Country",
  "487_4880": "99+",
  "417_10067": "freune@gmail.com",
  "487_4966": "Vehicle Management",
  "416_7733": "Zip/Postal Code",
  "30_3104": "Save",
  "I385_2823;366_12073": "UserName",
  "I411_5693;645_18159;30_32137": "Cancel",
  "415_7410": "1",
  "466_3884": "Notification",
  "I411_6023;645_18160;30_31931": "Confirm",
  "415_7453": "Import",
  "33_4940": "Admin Password Policy",
  "474_4326": "No history data.",
  "33_5335": "Require numbers.",
  "467_4299": "Reseted password successful!",
  "411_6306": "/",
  "411_6588": "Organization",
  "32_4724": "Confirm Root Password",
  "467_4259": "Postcode is wrong!",
  "415_7421": "Overview",
  "471_4319": "Error",
  "32_4688": "First Name",
  "177_9299": "Scopes",
  "I466_4001;645_18159;30_32137": "Cancel",
  "400_2163": "Active",
  "417_10120": "image 3",
  "411_6276": "Management rbac permission root path",
  "416_7713": "Address 1",
  "78_7468": "GET",
  "423_1919": "Your profile has been successfully updated.",
  "415_7346": "test71@gmail.com",
  "177_9382": "Client Secret",
  "65_5655": "Management idm organization child path selected",
  "66_6445": "Resource",
  "417_9553": "Identity Provider",
  "417_10122": "Organization",
  "411_5860": "Resource",
  "460_3175": "Please enter an integer value",
  "176_8996": "Create",
  "178_9681": "Lorem ipsum",
  "417_8054": "/",
  "32_4805": "Root Password",
  "79_7726": "Path",
  "79_7728": "Service",
  "417_8013": "Middle Name",
  "178_9824": "App Auth Code",
  "462_3598": "The External Permission has been successfully deleted.",
  "415_7555": "Dang Nhap",
  "65_5624": "Management idm organization child path request approval",
  "466_3891": "The External Group Permission has been successfully created.",
  "415_7294": "test61@gmail.com",
  "417_9590": "https://admin-portal-dev.newlands.organiclink.cloud/dashboard",
  "400_3291": "Limit",
  "400_2187": "Active",
  "66_5955": "Service",
  "400_3810": "User Role",
  "71_6612": "Name",
  "417_8735": "Administrator Role 02",
  "416_7755": "Phone",
  "417_8689": "Dat",
  "32_4572": "admin@netko.com0",
  "135_8581": "Enter Policies!",
  "177_9136": "Rectangle 26",
  "461_3383": "Enter Method",
  "176_8862": "Auth Provider",
  "461_3376": "PUT",
  "417_10126": "Organization",
  "33_5357": "Maximum Password Length (Characters)",
  "411_6393": "Permission Type",
  "I385_2826;405_13262;405_13239;144_59614": "9",
  "486_4368": "Name is required",
  "415_7243": "-",
  "467_4263":
    "Your list IP addresses has a few items that are not in the correct format. Please check again.",
  "460_3169": "Please enter an integer value",
  "515_10449": "Enter confirm password",
  "91_8193": "6",
  "410_5522": "/",
  "91_8155": "test21@gmail.com",
  "410_5421": "User Role",
  "522_10474": "Confirm Password needs to match Password",
  "417_8673": "Enter First Name!",
  "28_2469": "0",
  "32_4816": "",
  "410_5494": "User Role Name",
  "466_3983": "Active",
  "137_8805": "Enter Email!",
  "33_5123": "IP Address",
  "I466_4109;645_18159;30_32137": "Cancel",
  "177_9178": "Select",
  "415_7601": "Deactive",
  "90_7847": "Status",
  "405_4955": "Email",
  "417_10077": "",
  "30_3201": "Management rbac permission child path selected",
  "416_7843": "Last Name",
  "400_2212": "Administrator Role 01",
  "518_10465": "Reject",
  "416_7864": "Address",
  "411_5550": "Create Resource",
  "32_4799": "Address",
  "33_5476": "1",
  "405_4987": "2915 N 21st Ave",
  "415_6720": "Update External Permission",
  "32_4810": "IDM Quadruple",
  "411_6296": "View Permissions",
  "461_3489": "users selected",
  "280_1840": "Enforce Password History",
  "32_4694": "Phone",
  "457_3136": "The Access Limit has been successfully updated.",
  "461_3508": "Confirm",
  "411_6173": "Preview Internal Permission",
  "415_7440": "Deleted User",
  "474_4344": "Create User Role",
  "411_6172": "/",
  "466_4014": "Notification",
  "I417_8910;645_18160;30_31931": "Confirm",
  "73_7130": "Select",
  "487_4942": "Inbox",
  "411_5871": "Method",
  "416_7709": "First Name",
  "417_10065": "IDM Quadruple",
  "33_5347": "Require lowercase letter.",
  "90_8140": "Status",
  "91_8237": "Phone Code",
  "30_3208": "Can selected action rbac permission",
  "416_7928": "Administrator",
  "417_8196": "Update",
  "I415_7651;645_18160;30_31931": "Confirm",
  "136_8707": "Enter External Group Permission",
  "385_2167": "5",
  "411_6091": "Control Plane",
  "416_7731": "State/Province",
  "417_9598": "Icon",
  "416_7840": "Middle Name",
  "385_3576": "Management cp organization child path detail",
  "79_7741": "PUT",
  "415_7381": "Active",
  "400_2140": "Netko Company HR",
  "487_4869": "My Notification",
  "79_7565": "Service",
  "417_8691": "Middle Name",
  "417_8970": "Delete",
  "467_4255": "Your password should not have whitespace",
  "385_3596": "976",
  "400_2232": "30",
  "405_5032": "Logo",
  "385_3574": "Management approval child path update",
  "400_2155": "43",
  "137_8754": "Delete",
  "417_9559": "Update",
  "415_7433": "13",
  "66_6059": "ID",
  "411_6177": "Preview",
  "417_8713": "City",
  "136_8625": "Logo is required",
  "417_8877": "/",
  "417_8658": "Create User",
  "91_8410": "Zip/Postal Code",
  "411_6391": "Organization",
  "416_7880": "Last Name",
  "66_5790": "Internal Permission Name",
  "415_7284": "Username",
  "33_5231": "Allow Special Symbol",
  "178_9535": "Lorem ipsum",
  "177_9385": "Scopes",
  "461_3520": "Confirm",
  "178_9529": "Created",
  "417_8009": "First Name",
  "411_6502": "Preview User Role",
  "33_5228": "Maximum Password Length (Characters)",
  "30_3794": "Select",
  "415_7145": "External Group Permission",
  "417_8711": "Address 2",
  "91_8152": "test61@gmail.com",
  "514_10290": "Data",
  "I466_4001;645_18156": "Are you sure you want to delete User?",
  "78_7278": "GET",
  "33_5212": "Update",
  "400_3299": "Update Organization",
  "400_3300": "/",
  "417_8400": "Deleted User",
  "415_7131": "Service",
  "461_3372": "All",
  "255_2023": "Role:",
  "65_5686": "Management idm organization child path delete",
  "410_5535": "Enter User Role Name!",
  "385_2222": "External Permission",
  "467_4265": "Let's add more IP Addresses",
  "411_6505": "User Role",
  "400_3205": "1",
  "411_5890": "/",
  "91_8164": "Active",
  "400_3272": "Organization",
  "176_8860": "Status",
  "177_9268": "Enter Client Secret",
  "177_9312": "Enter Auth Scopes",
  "385_3616": "Management rbac permission child path selected",
  "417_9588": "client_secret_321",
  "417_8670": "Last Name",
  "136_8696": "Edit",
  "417_8717": "Zip/Postal Code",
  "136_8680": "Enter Service",
  "405_5013": "imd-quadruple",
  "136_8619": "Enter email!",
  "410_5409": "Save",
  "255_1972": "Gender",
  "410_5335": "Create",
  "73_7135": "Description",
  "79_7568": "Save",
  "71_6640": "2",
  "417_9554": "/",
  "73_7128": "Service",
  "32_4571": "OmAdmin",
  "417_8881": "Update",
  "91_8154": "test12@gmail.com",
  "177_9263": "Enter Organization",
  "466_4096": "The Identity Provider has been successfully deleted.",
  "136_8624": "Enter Code!",
  "78_7539": "root_idm_organization.idm_organization_path_delete",
  "466_4145": "Notification",
  "91_8376": "Organization",
  "417_10099": "tax 111",
  "416_7735": "Country",
  "461_3378": "DELETE",
  "416_7924": "1234567841",
  "411_6301": "Management rbac permission child path selected",
  "73_6976": "Select",
  "515_10447": "Enter current password",
  "415_7544": "Deactive",
  "91_8430": "Organization",
  "32_4564": "0987654321",
  "I411_5693;645_18156": "Are you sure you want to delete Resources?",
  "417_9557": "Identity",
  "415_7418": "Create",
  "136_8620": "Enter Code!",
  "405_5041": "/",
  "30_3102": "Has parent Policy",
  "417_9752": "Rectangle 26",
  "417_8723": "+89",
  "417_8045": "+84",
  "255_1980": "Language",
  "65_5696": "Management idm organization child path update",
  "I466_4110;645_18156": "Are you sure you want to deactive Identity Provider?",
  "90_7885": "Tran A",
  "411_5862": "Update Resource",
  "65_5665": "835",
  "32_4828": "Phoenix",
  "415_7354": "test41@gmail.com",
  "178_9521": "Logo",
  "471_4322": "image 5",
  "416_7845": "Last Name",
  "65_5600": "Management approval child path detail",
  "78_7536": "root_rbac_permission.rbac_permission_path_selected",
  "73_7145": "Service",
  "417_8697": "Tran",
  "91_8153": "test71@gmail.com",
  "32_4684": "Tax Code",
  "400_3298": "Organization",
  "78_7284": "approval_path_change_status",
  "30_3112": "Management rbac permission child path selected",
  "474_4342": "Create Administrator Role",
  "65_5651": "Management idm organization child path selected",
  "400_3315": "End-User Password Policy",
  "32_4723": "Root Password",
  "33_5267": "Check allowed characters (including |a-zA-z0-9| and allowed special symbol).",
  "417_8799": "Tran",
  "28_2468": "Users",
  "136_8701": "Enter Name!",
  "30_4438": "Organization Management",
  "177_9384": "Name",
  "417_8886": "/",
  "91_8159": "test41@gmail.com",
  "417_8035": "admin.1@organiclink.cloud",
  "400_2709": "/",
  "66_6260": "Create",
  "411_6508": "Preview",
  "417_9566": "Platform",
  "405_5003": "Phoenix",
  "I385_2826;405_13251;405_11583": "Inbox",
  "400_3328": "Organization",
  "177_9407": "https://admin-portal-dev.newlands.organiclink.cloud/dashboard",
  "28_2455": "0",
  "466_4108": "The Identity Provider has been successfully deactived",
  "177_9269": "Enter Callback URL",
  "136_8705": "Enter Resource",
  "78_7484": "965",
  "71_6576": "ID",
  "423_2988": "No data",
  "137_8758": "Delete",
  "460_3171": "Please input Maximum Password Length!",
  "400_3219": "38",
  "417_9576": "0",
  "467_4292": "Updated successful!",
  "66_5954": "Internal Permission Name",
  "I466_3929;645_18160;30_31931": "Confirm",
  "178_9517": "Action",
  "417_10055": "Admin Password Policy",
  "410_5527": "Update",
  "400_2136": "Administrator",
  "135_8578": "Enter User Role Name!",
  "I385_2823;366_12079": "Organization Setting",
  "65_5631": "975",
  "66_6336": "External Permission Name",
  "460_3225": "Cancel",
  "385_3587": "967",
  "415_7120": "Update External Group Permission",
  "416_7874": "Matsubara",
  "400_3273": "Update",
  "417_9879": "Detail Application",
  "91_8225": "Phone",
  "400_2713": "Create",
  "78_7519": "idm_organization_path_request_approval",
  "417_10038": "Address",
  "415_7300": "12",
  "457_3123": "Enter Whitelist IP To",
  "415_7569": "Deactive",
  "466_3996": "Edit",
  "461_3579": "The Internal Permission has been successfully deleted.",
  "411_6281": "Management rbac permission child path selected",
  "33_5200": "7",
  "461_3500": "Warning",
  "177_9294": "Client ID",
  "417_8682": "Enter Password!",
  "405_4957": "Organization Code",
  "417_10111": "1234567841",
  "461_3426": "Notification",
  "178_9829": "Active",
  "462_3592": "The External Permission has been successfully created.",
  "461_3380": "GET",
  "78_7267": "Method",
  "515_10435": "/",
  "66_6460": "Name",
  "400_3330": "/",
  "73_7149": "External Group Permission 1",
  "32_4594": "Select Organization Type",
  "385_3548": "Policy ID",
  "461_3459": "Notification",
  "486_4362": "Service",
  "415_6722": "External Permission",
  "411_6261": "Service",
  "30_3197": "Management rbac permission root path",
  "32_4798": "Email",
  "30_3195": "Parent Policy",
  "415_7534": "Deactive",
  "66_5960": "Management approval child path change status",
  "I474_4327;623_21593": "Automatically create a password.",
  "457_3122": "Enter Whitelist IP From",
  "I411_5693;645_18160;30_31931": "Confirm",
  "415_7542": "Deactive",
  "136_8681": "Enter Permission",
  "400_1945": "Policy",
  "467_4261": "Minimum password length can not bigger than Maximum password length",
  "461_3401": "Notification",
  "385_2851": "Bien Hoang need you to Approval 5 Wrapper API Creation Request\n07:23 16/11/2023",
  "33_5473": "Key ID",
  "411_5875": "Create",
  "416_7842": "Middle Name",
  "65_5614": "Management idm organization child path detail",
  "416_7876": "Middle Name",
  "135_8563": "Enter Service!",
  "183_12456": "History",
  "73_7147": "Save",
  "417_8966": "Edit",
  "91_8429": "Phone",
  "90_7839": "Action",
  "400_2222": "46",
  "487_4909": "Reject",
  "400_3269": "/",
  "467_4267": "Value should be only special characters.",
  "417_8041": "Owner OmAdmin",
  "462_3597": "Notification",
  "65_5648": "Management idm organization child path selected",
  "32_4569": "Active",
  "90_7853": "Hoang B",
  "29_2936": "Service",
  "415_7695": "Active",
  "400_2208": "User",
  "137_8734": "Enter Organization",
  "176_8885": "Active",
  "417_9877": "Application",
  "416_7916": "Owner OmAdmin",
  "417_9686": "Preview",
  "30_4456": "Create",
  "385_3599": "Management rbac permission child path selected",
  "137_8733": "Enter External Group Permission Name!",
  "32_4708": "Address 2",
  "411_6075": "/",
  "410_5503": "client_admin_test_14",
  "411_6196": "-",
  "417_9569": "Name",
  "417_8703": "Address",
  "460_3161": "Please enter an integer value",
  "400_3815": "Create",
  "411_6587": "User Role Name",
  "78_7524": "POST",
  "33_5368": "Update",
  "417_9663": "Preview Identity Provider",
  "400_2198": "Organic Mobility Secondary Driver",
  "33_4948": "OTP Management",
  "400_2122": "User",
  "32_4696": "Phone Code",
  "417_10123": "/",
  "90_8038": "Overview",
  "416_7868": "Phone",
  "30_4452": "Action",
  "385_2185": "Policies",
  "460_3167": "Value should be only special characters.",
  "411_6397": "Owner client_admin_test_14",
  "417_9562": "Config Url",
  "466_4033": "The User has been successfully deactived",
  "417_8031": "Country",
  "91_8428": "Email",
  "385_2191": "User Roles",
  "30_3874": "Permission Type",
  "457_3135": "Notification",
  "177_9154": "Config URL",
  "417_10089": "85015",
  "467_4288": "You have successfully approved the request.",
  "405_5005": "22",
  "66_6448": "/api/v1/user",
  "410_5499": "Save",
  "466_4008": "Notification",
  "I466_4003;645_18159;30_32137": "Cancel",
  "487_4892": "Get IDM",
  "I415_6987;645_18155": "Warning",
  "73_6960": "Save",
  "91_8402": "Address",
  "28_444": "Overview",
  "28_2461": "Roles",
  "32_4855": "1234567841",
  "417_8887": "Preview User",
  "410_5519": "External Permissions",
  "32_4802": "Tax-Code",
  "417_8705": "Phone",
  "417_8731": "Country",
  "411_6421": "/",
  "515_10423": "Task",
  "400_2227": "44",
  "217_1681": "Notification",
  "417_9691": "Update",
  "176_8994": "Search",
  "136_8674": "Edit",
  "417_8999": "Identity Provider",
  "78_7298": "api-idm",
  "417_8053": "Administrator",
  "400_2581": "Identity Management",
  "79_7574": "Select",
  "91_8397": "Roles",
  "136_8621": "Enter Phone!",
  "460_3275": "Notification",
  "415_7429": "13",
  "255_2027": "Upload",
  "410_5331": "Create User role",
  "411_5879": "root_approval.approval_path_change_status",
  "415_7532": "Deactive",
  "33_5478": "ABC",
  "461_3384": "Enter Resource",
  "417_8677": "Enter First Name!",
  "400_2184": "15",
  "402_2374": "Active",
  "487_4981": "Send Email",
  "I466_3945;645_18160;30_31931": "Confirm",
  "32_4676": "Organization Code",
  "456_3118": "Notification",
  "411_6310": "Preview - Policy",
  "411_6288": "rbac_permission_path_selected",
  "32_4806": "Confirm Root Password",
  "411_6501": "/",
  "400_3314": "/",
  "487_4894": "29-01-2024 15:52",
  "417_10114": "Logo",
  "411_6504": "-",
  "405_4956": "Address",
  "255_2013": "Security",
  "417_8893": "Preview User",
  "467_4293": "Created successful!",
  "136_8618": "Enter Organization Type",
  "400_3289": "Access Limit",
  "487_4954": "Inbox",
  "400_2077": "Status",
  "415_7618": "Delete",
  "415_7125": "Update",
  "411_6422": "Preview Administrator Role",
  "33_5251": "Require lowercase letter.",
  "466_3984": "Deactive",
  "400_1946": "/",
  "183_12442": "Delete",
  "466_4084": "The Identity Provider has been successfully updated.",
  "385_2195": "Resources",
  "417_8004": "Email",
  "467_4264": "Your IP address from needs to be smaller than IP address to.",
  "411_6387": "rbac_permission_path_selected",
  "73_7151": "Description",
  "385_3559": "972",
  "417_10103": "Service Provider 2B",
  "467_4297": "Approval approved successfully",
  "411_6584": "rbac_permission_path_selected",
  "467_4296": "Request approval successful!",
  "411_5881": "Control Plane",
  "423_1952": "No data",
  "255_1999": "State/Prefecture",
  "411_6390": "User Role Name",
  "415_7614": "Edit",
  "415_7432": "Pending User",
  "410_5419": "Update User Role",
  "466_4021": "The User has been successfully deleted.",
  "415_7246": "ID",
  "467_4231": "Email is invalid!",
  "417_9667": "Identity Provider",
  "410_5521": "User Role",
  "514_10305": "Organization",
  "30_3784": "Organization",
  "486_4390": "rbac_permission_path_selected",
  "29_2955": "Description",
  "461_3567": "The Internal Permission has been successfully updated.",
  "91_8382": "Phone",
  "32_4851": "+84",
  "415_7447": "Action",
  "90_8144": "Action",
  "417_8049": "1234567841",
  "I466_4003;645_18156": "Are you sure you want to deactive User?",
  "487_4982": "Turn on if you would like to receive notifications by email.",
  "177_9424": "Customize Login Button",
  "78_7316": "Search",
  "71_6777": "Create",
  "461_3532": "Confirm",
  "415_7040": "/",
  "410_5411": "Org test 22 Passenger",
  "411_6399": "client_admin_test_14",
  "514_10280": "Profile Setting",
  "417_10063": "OTP Management",
  "78_7463": "idm_func_setting_path_selected",
  "415_7119": "/",
  "385_2869": "View All",
  "I385_2823;974_98967": "Profile Setting",
  "177_9383": "Callback Url",
  "178_9557": "Search",
  "65_5737": "Action",
  "415_7340": "Duong A",
  "466_4089": "Notification",
  "415_7356": "test51@gmail.com",
  "385_3572": "Management dashboard control child path view",
  "65_5698": "978",
  "460_3231": "Warning",
  "91_8178": "Active",
  "417_8055": "Preview Administrator",
  "461_3356": "The Resource has been successfully deleted.",
  "71_6614": "Description",
  "32_4473": "Phone",
  "411_6066": "Create Interal Permission",
  "78_7263": "Service Code",
  "460_3227": "Confirm",
  "439_2471": "Vehicles",
  "136_8629": "Delete",
  "417_8693": "Van",
  "66_6462": "Path",
  "178_9527": "Description",
  "461_3460": "The Organization Password has been successfully reset",
  "90_7937": "7",
  "385_2831": "Friday, 17 Nov 2023",
  "417_8058": "Administrator",
  "I417_8910;645_18155": "Warning",
  "91_8208": "1",
  "461_3561": "The Role has been successfully deactived.",
  "417_8384": "Total User",
  "255_1959": "First Name",
  "400_2019": "Update Policy",
  "415_7242": "Preview",
  "32_4494": "994564354",
  "417_10160": "Service User",
  "411_5868": "Path",
  "411_6264": "Resources",
  "411_5889": "Resource",
  "32_4808": "Save",
  "30_3106": "Management rbac permission child path selected",
  "400_3270": "Update Organization",
  "33_5351": "Require uppercase letter.",
  "66_6301": "Method",
  "90_7951": "User",
  "400_2075": "ID",
  "400_3319": "End-User Password Policy",
  "467_4262": "Maximum password length can not smaller than Minimum password length",
  "66_6311": "Code",
  "I513_9947;154_61665": "Current password",
  "417_9584": "Web",
  "32_4862": "Upload",
  "177_9298": "Name",
  "32_4592": "Enter The Name",
  "415_7436": "Locked User",
  "486_4360": "Name",
  "417_10087": "22",
  "415_6719": "/",
  "461_3453": "Notification",
  "I415_6987;645_18159;30_32137": "Cancel",
  "415_7369": "Active",
  "91_8158": "test31@gmail.com",
  "415_7292": "Hoang B",
  "410_5406": "User Role Name",
  "415_6724": "Update",
  "78_7527": "root_approval.approval_path_change_status",
  "416_7751": "Phone Code",
  "411_6083": "Internal Permission Name",
  "78_7295": "rbac_permission_path_selected",
  "66_6186": "External Permission",
  "515_10452": "Are you sure to deactivate this account?",
  "466_4101": "Notification",
  "177_9182": "Select",
  "487_4958": "Approval",
  "78_7537": "root_idm_func_setting.idm_func_setting_path_selected",
  "460_3263": "The Role has been successfully deleted.",
  "400_3311": "Organization",
  "417_10071": "",
  "415_7033": "Edit",
  "515_10434": "Profile Setting",
  "410_5330": "/",
  "416_7884": "Address 1",
  "486_4370": "Method is required",
  "400_2708": "Organization",
  "415_7398": "7",
  "415_7147": "Preview External Group Permission",
  "416_7860": "Save",
  "71_6608": "Action",
  "410_5333": "User Role",
  "33_5366": "Back",
  "32_4722": "",
  "467_4301": "You need to select records which you want action.",
  "29_3034": "Select",
  "65_5767": "Create",
  "65_5621": "Management idm organization child path request approval",
  "176_8883": "Active",
  "415_7540": "Deactive",
  "137_8810": "Enter Role",
  "79_7712": "Method",
  "411_6076": "Update Interal Permission",
  "415_7443": "Status",
  "460_3268": "Notification",
  "415_6733": "External Group Permission",
  "91_8465": "Administrator Role 02",
  "91_8373": "Email",
  "29_2945": "Policy",
  "466_4026": "Notification",
  "417_8005": "Organization",
  "32_4561": "om-admin",
  "65_5641": "840",
  "417_9883": "Detail",
  "385_3569": "968",
  "417_9906": "Middle Name",
  "176_9074": "Action",
  "136_8622": "Enter Password!",
  "467_4300":
    "Deactive successful. Please contact the admin to be active again if you want to continue using.",
  "467_4303": "An error has occurred, please try again or contact the administrator.",
  "460_3253": "Are your sure to delete batch User Role?",
  "466_3928": "The Administrator has been successfully deactived",
  "33_5358": "Allow Special Symbol",
  "33_5117": "Enter the IP addresses to allow access, separated by commas.",
  "416_7906": "Country",
  "91_8392": "Confirm Password",
  "417_9001": "Create Identity Provider",
  "178_9650": "android",
  "487_4940": "99+",
  "416_7937": "Save",
  "90_7939": "8",
  "415_7342": "Bui A",
  "460_3173": "Maximum password length can not smaller than Minimum password length.",
  "486_4379": "Attach External Permission",
  "416_7865": "Email",
  "I415_6987;645_18156": "Are you sure you want to delete External Group Permission?",
  "417_8029": "Zip/Postal Code",
  "91_8160": "test51@gmail.com",
  "66_6337": "Organization",
  "411_6069": "Create",
  "415_7600": "Active",
  "66_6488": "External Group Permission",
  "415_7350": "test21@gmail.com",
  "460_3160": "Please input Maximum Password Length!",
  "90_7887": "Bui A",
  "415_7363": "Active",
  "460_3176": "Please input Allow Special Symbol!",
  "90_7813": "ID",
  "400_3268": "Organization",
  "461_3361": "Notification",
  "78_7510": "POT",
  "28_2433": "Organizations",
  "415_7135": "Description",
  "32_4590": "Save",
  "416_7898": "Address 2",
  "400_2113": "Name",
  "460_3163": "Please input Enforce Password History!",
  "91_8416": "Country",
  "79_7717": "Resource",
  "135_8562": "Enter Policy Name!",
  "410_5501": "Owner client_admin_test_14",
  "416_7700": "Address",
  "I466_4002;645_18160;30_31931": "Confirm",
  "460_3162": "Maximum password length can not smaller than Minimum password length.",
  "410_5538": "Attach External Permission",
  "255_1989": "Address",
  "400_3812": "Create Administrator Role",
  "411_6085": "Permission",
  "416_7882": "Matsubara",
  "467_4257": "Your name should not be empty.",
  "410_5530": "Attach External Permission",
  "91_8375": "Email.",
  "410_5407": "Organization",
  "467_4266": "Please enter an integer value",
  "30_3783": "User Role Name",
  "385_2169": "IDM Portal",
  "461_3454": "The Organization has been successfully deactive.",
  "417_8816": "Back",
  "515_10431": "Maximum Password Length (Characters)",
  "28_2434": "3",
  "33_5120": "IP Address",
  "416_7878": "Matsubara",
  "400_3295": "Update Organization",
  "417_9564": "Organization",
  "417_10041": "Tax-Code",
  "I457_3124;623_21593": "Let's add more IP Addresses",
  "33_5356": "Minimum Password Length (Characters)",
  "415_7336": "Pham A",
  "177_9405": "client_secret_321",
  "411_6598": "rbac_permission_path_selected",
  "I466_4001;645_18160;30_31931": "Confirm",
  "417_8715": "State/Provine",
  "400_2179": "16",
  "417_8021": "Address 1",
  "78_7287": "api-control-plane",
  "I415_7651;645_18159;30_32137": "Cancel",
  "415_6852": "Update",
  "461_3362": "The Resource has been successfully updated.",
  "461_3381": "Enter Service",
  "65_5626": "977",
  "136_8623": "Enter Confirm Password!",
  "183_12461": "Edit",
  "405_4958": "Representative",
  "135_8579": "Enter Organization",
  "33_5210": "Back",
  "178_9821": "Description",
  "417_10095": "imd-quadruple",
  "66_6470": "External Permission 01",
  "177_9295": "Customize Login Button",
  "30_4305": "Action",
  "90_7849": "Role",
  "32_4573": "admin@organiclink.cloud",
  "255_1977": "Birthday",
  "411_5877": "/api/v1/internal/approvals/{id}/change_approval_status12/",
  "30_3120": "Management rbac permission child path selected",
  "460_3165": "Please enter an integer value",
  "411_6262": "Parent Policy",
  "66_5799": "Select",
  "400_2220": "Active",
  "467_4258": "My Config URL should not have HTTP/HTTPS",
  "417_9944": "Female",
  "467_4256": "Confirm Password needs to match New Password",
  "466_3903": "The Administrator has been successfully updated.",
  "65_5735": "Search",
  "65_5689": "Management idm organization child path delete",
  "385_3554": "974",
  "416_7825": "/",
  "178_9643": "App Code",
  "71_6690": "3",
  "466_3897": "The External Group Permission has been successfully deleted.",
  "439_2479": "Identity Provider",
  "461_3420": "Notification",
  "415_7128": "External Group Permission Name",
  "91_8449": "Test92@gmail.com",
  "78_7271": "approval_path_detail",
  "66_5958": "Save",
  "415_7565": "14:01 2024-02-01",
  "466_3914": "Notification",
  "400_2710": "Create Organization",
  "30_3101": "Description",
  "29_3090": "Has parent Policy",
  "385_2841": "[OM] - The system will be in maintain status at 12:00 today\n07:23 17/11/2023",
  "415_7288": "Role",
  "I385_2826;405_13268;405_11583": "Tasks",
  "255_1986": "Email",
  "400_2215": "Administrator",
  "I461_3428;623_21687": "If you entered the Password , please fill in the Password Confirmation!",
  "461_3421": "The Organization has been successfully updated.",
  "515_10425": "Allow Special Symbol",
  "I466_4002;645_18156": "Are you sure you want to active User?",
  "90_7861": "12",
  "411_6606": "Update",
  "I513_9981;154_61666;75_53865;250_37504": "You can reactivate whenever you want.",
  "461_3572": "Notification",
  "32_4690": "Last Name",
  "90_7943": "10",
  "417_8706": "Organization",
  "486_4387": "Attach External Permission",
  "400_2217": "48",
  "32_4728": "Upload",
  "474_4325": "Approval History",
  "400_3329": "Update Organization",
  "400_3287": "Organization",
  "136_8616": "Enter Last Name!",
  "460_3262": "Notification",
  "410_5319": "Organization",
  "71_6673": "External Group Permission 01",
  "66_6190": "Search",
  "410_5537": "External Permission is required",
  "417_8668": "Middle Name",
  "415_6714": "Create External Permission",
  "I466_4110;645_18159;30_32137": "Cancel",
  "177_9409": "Dang Nhap",
  "417_10037": "Email",
  "415_7694": "All",
  "177_9378": "Client ID",
  "177_9133": "Created",
  "402_2377": "Delete",
  "515_10454": "Yes",
  "255_2015": "Personal data usage history",
  "177_9310": "Logo is required",
  "410_5418": "/",
  "518_10473": "Approve",
  "411_6303": "Can selected action rbac permission",
  "462_3584": "Notification",
  "66_6340": "Save",
  "415_7137": "Netko Company",
  "416_7854": "Role",
  "73_7133": "Description",
  "417_8676": "Enter Email!",
  "415_7146": "/",
  "255_1955": "User Profile",
  "177_9158": "Auth Provider",
  "400_3217": "1",
  "91_8426": "Address",
  "91_8227": "Save",
  "467_4254": "Your password should have 6 characters or more",
  "415_7424": "Total User",
  "32_4832": "22",
  "461_3390": "The Policy has been successfully updated.",
  "91_8441": "City",
  "78_7477": "idm_organization_path_delete",
  "415_7408": "6",
  "411_5885": "PUT",
  "461_3494": "Cancel",
  "385_2856": "Bien Hoang need you to Approval the Translation Request\n10:23 16/11/2023",
  "417_10059": "End-User Password Policy",
  "411_5865": "Update Resource",
  "417_8953": "Active",
  "417_8683": "Enter Confirm Password!",
  "417_9878": "/",
  "415_6753": "External Group Permission",
  "385_3590": "Management approval_setting child path detail",
  "400_3220": "to",
  "I466_4001;645_18155": "Warning",
  "177_9411": "profile",
  "71_6707": "Search",
  "400_2015": "/",
  "466_3985": "Locked",
  "411_6064": "Internal Permission",
  "65_5617": "Management idm organization child path detail",
  "79_7737": "Control Plane",
  "416_7707": "Confirm Password",
  "486_4372": "Update",
  "417_8695": "Last Name",
  "I466_4003;645_18160;30_31931": "Confirm",
  "65_5679": "Management rbac permission child path selected",
  "417_8791": "Dat",
  "255_1995": "Enter City",
  "91_8187": "test11@gmail.com",
  "416_7853": "Confirm Password",
  "416_7727": "Address 2",
  "417_9597": "Customize Login Button",
  "66_5962": "Control Plane",
  "400_3301": "/",
  "28_448": "Administrators",
  "30_4271": "Logo",
  "255_1982": "English",
  "91_8443": "State/Provine",
  "461_3496": "Confirm",
  "29_2943": "Save",
  "515_10426": "Require alphabetic letter",
  "73_6957": "Organization",
  "417_9000": "/",
  "416_7856": "Select",
  "I513_9947;154_61666;75_53865;250_37564": "Enter current password",
  "415_7152": "External Group Permission ",
  "415_7441": "13",
  "486_4365": "Method",
  "90_7845": "Username",
  "487_4882": "Inbox",
  "385_3608": "971",
  "32_4585": "Organization",
  "415_7282": "Name",
  "411_6268": "Back",
  "411_5546": "Create Resource",
  "177_9157": "Organization",
  "33_4944": "End-User Password Policy",
  "78_7538": "root_idm_organization.idm_organization_path_detail",
  "415_7334": "Le B",
  "400_2167": "Netko Company External Driver",
  "411_6199": "Update",
  "466_4009": "The User has been successfully updated.",
  "177_9162": "Save",
  "487_4938": "Approval",
  "466_3908": "Notification",
  "135_8564": "Enter Group Permission!",
  "461_3367": "Notification",
  "515_10448": "Enter new password",
  "486_4367": "Create",
  "417_8006": "Role",
  "178_9645": "Platform",
  "71_6703": "External Group Permission",
  "486_4382": "rbac_permission_path_selected",
  "415_6739": "/resource/edit",
  "176_8858": "Name",
  "415_7041": "Create External Group Permission",
  "416_7866": "Organization",
  "415_7304": "Lê B",
  "417_8793": "Middle Name",
  "487_5007": "My Notification",
  "255_2024": "admin@organiclink.cloud",
  "66_6463": "Organization",
  "417_9754": "Lorem ipsum",
  "400_3302": "Admin Password Policy",
  "411_6307": "Preview Policy",
  "417_9555": "Update Identity Provider",
  "415_7039": "External Group Permission",
  "456_3119": "Request approval successful!",
  "515_10455": "Warning",
  "78_7382": "Create",
  "385_2847": "Thurday, 16 Nov 2023",
  "91_8437": "Address 1",
  "32_4691": "Email",
  "66_6322": "permission.external",
  "33_5131": "Back",
  "415_7553": "Rectangle 26",
  "385_3512": "Name",
  "411_6068": "Internal Permission",
  "33_5129": "Add more",
  "487_4970": "Logistic Connect",
  "415_7697": "Locked",
  "32_4701": "Phone",
  "417_8678": "Enter Middle Name!",
  "461_3566": "Notification",
  "415_7522": "Deactive",
  "460_3159": "Minimum password length can not bigger than Maximum password length.",
  "405_5029": "1234567841",
  "137_8807": "Enter Password!",
  "405_5055": "*****",
  "415_7122": "External Group Permission",
  "91_8185": "Bui A",
  "385_3622": "Management cp organization child path view",
  "487_4934": "General",
  "183_12465": "Delete",
  "177_9292": "Client ID",
  "I411_6023;645_18159;30_32137": "Cancel",
  "30_3792": "User Role",
  "415_6933": "Preview - External Permission",
  "415_7344": "Bùi Phương Thảo",
  "29_2834": "Create Policy",
  "405_5056": "-",
  "410_5515": "Internal Permissions",
  "I415_6665;645_18159;30_32137": "Cancel",
  "417_8015": "Matsubara",
  "411_6065": "/",
  "461_3395": "Notification",
  "78_7291": "PUT",
  "255_2019": "Log out",
  "32_4706": "Address 1",
  "30_3879": "Internal Permissions",
  "385_3578": "Management cp organization child path create",
  "32_4801": "Representative",
  "66_6451": "Select",
  "417_8401": "13",
  "I466_3929;645_18155": "Warning",
  "417_8954": "Deactive",
  "416_7930": "Update Administrator",
  "461_3402": "The Policy has been successfully created.",
  "30_3790": "Save",
  "33_5329": "Require special symbols defined above.",
  "461_3548": "Notification",
  "466_3915": "The Administrator has been successfully deleted.",
  "460_3174": "Please input Enforce Password History!",
  "415_7412": "Administrator",
  "178_9833": "android",
  "417_8876": "User",
  "400_2150": "45",
  "79_7729": "Method",
  "78_7494": "",
  "410_5495": "Organization",
  "32_4836": "Country",
  "417_8684": "Enter Organization",
  "461_3382": "Enter Path",
  "32_4712": "Post Code",
  "460_3177": "Value should be only special characters.",
  "411_6420": "User Role",
  "I385_2823;366_12081": "Logout",
  "66_5792": "Permission",
  "30_4318": "netko-company",
  "91_8383": "Password",
  "410_5329": "User Role",
  "415_7332": "Bui A",
  "513_9975": "Deactivate",
  "400_3218": "10",
  "78_7261": "Path",
  "66_6173": "3",
  "66_6156": "External Permission 01",
  "78_7522": "api-idm",
  "417_8880": "User",
  "177_9194": "URL",
  "417_10051": "Access Limit",
  "90_7843": "Name",
  "415_7384": "Active",
  "137_8735": "Enter Service",
  "417_10117": "Upload",
  "177_9379": "Organization",
  "28_2448": "145",
  "515_10429": "Require numbers",
  "466_3922": "The Administrator has been successfully activated.",
  "417_8017": "Last Name",
  "405_4962": "Root Password",
  "33_4932": "image 3",
  "400_2174": "Netko Company Passenger",
  "466_3982": "All",
  "385_2863": "Scroll to load",
  "417_8019": "Matsubara",
  "I385_2826;405_13262;405_11583": "Approval",
  "416_7851": "Email",
  "65_5713": "833",
  "178_9538": "Active",
  "415_7239": "Back",
  "461_3573": "The Internal Permission has been successfully created.",
  "415_7536": "Deactive",
  "461_3578": "Notification",
  "417_8037": "Om Admin",
  "29_3032": "Group Permission",
  "405_4960": "Organization Type",
  "417_8818": "Update",
  "487_4885": "Setting",
  "460_3156": "The Password Policy has been successfully updated.",
  "255_1997": "Postcode",
  "410_5532": "Select",
  "417_9572": "https://idm-portal-dev.newlands.organiclink.cloud/identity_provider/create",
  "66_6326": "POST",
  "177_9391": "CID_1234567890",
  "73_6962": "External Group Permission",
  "466_3885": "The External Group Permission has been successfully updated.",
  "417_9568": "Callback Url",
  "400_3222": "results",
  "30_4309": "Name",
  "487_4884": "99+",
  "I466_3929;645_18156": "Are you sure you want to active Administrator?",
  "177_9381": "Platform",
  "I415_7651;645_18155": "Warning",
  "33_5257": "Require uppercase letter.",
  "417_9570": "Scopes",
  "410_5413": "Org test 229",
  "400_2016": "Update Policy",
  "385_3544": "Action",
  "255_1991": "Address 1",
  "66_6461": "Resource",
  "400_2182": "Active",
  "467_4302": "You need to select records for the chosen action.",
  "30_4416": "Active",
  "417_8003": "Address",
  "I466_4110;645_18155": "Warning",
  "30_3192": "rbac_permission_path_selected",
  "400_3216": "Show",
  "255_2022": "Owner OmAdmin",
  "78_7302": "GET",
  "73_7157": "netko-company-service-test-1",
  "65_5556": "ID",
  "177_9377": "Config Url",
  "417_10107": "+84",
  "71_6618": "External Group Permission",
  "515_10430": "Minimum Password Length (Characters)",
  "78_7482": "DELETE",
  "487_4893": "test idm . From service api-idm raise error 200",
  "I513_9965;154_61666;75_53865;250_37564": "Enter confirm password",
  "30_3200": "View Permissions",
  "I457_3138;623_21687":
    "Your list IP addresses has a few items that are not in the correct format. Please check again.",
  "178_9533": "Rectangle 26",
  "405_5021": "Service Provider 2B",
  "487_4936": "99+",
  "417_9662": "/",
  "33_5195": "Require special symbols defined above.",
  "29_2821": "Action",
  "385_2836": "Bien Hoang need you to Approval the Translation Request\n10:23 17/11/2023",
  "137_8750": "Edit",
  "73_6952": "External Group Permission Name",
  "415_6735": "External Permission 01",
  "32_4834": "85015",
  "461_3554": "Notification",
  "411_6084": "Service",
  "30_4450": "Search",
  "I385_2826;405_11590;405_11754": "All",
  "91_8202": "tan.le+3@netko-solution.com",
  "415_6727": "Name",
  "385_2205": "User",
  "65_5596": "Description",
  "415_7141": "netko-company-service-test-1",
  "I466_3945;645_18155": "Warning",
  "400_2247": "Action",
  "416_7849": "Email",
  "I415_6665;645_18156": "Are you sure you want to delete External Permission?",
  "460_3228": "Are your sure to delete User Role?",
  "30_3098": "Service",
  "66_6435": "Select",
  "136_8700": "Delete",
  "400_2230": "Active",
  "410_5321": "Save",
  "32_4843": "tax 111",
  "461_3348": "Your name should not be empty.",
  "91_8378": "Select",
  "400_1950": "Create  Policy",
  "460_3192": "Please input Key Token!",
  "65_5607": "Management idm function setting selected",
  "I513_9965;154_61665": "Confirm password",
  "33_5364": "@#$%^&",
  "417_8666": "Middle Name",
  "487_4878": "Approval",
  "91_8151": "test92@gmail.com",
  "65_5708": "836",
  "33_5118": "From",
  "411_6500": "User Role",
  "411_5870": "Service",
  "400_2129": "User",
  "415_7406": "11",
  "461_3549": "The Role has been successfully created.",
  "417_8961": "History",
  "32_4710": "City",
  "410_5423": "Update",
  "515_10453": "No",
  "73_7143": "Description",
  "78_7312": "Resources",
  "217_4561": "Country Code"
}

export default localeDefault
